import { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../assets/logo_1x.png";
import Button from "../components/atoms/Button";
import { getQueuesAPI } from "../util";
import Spinner from "../components/atoms/Spinner";

export default function ForgotPassword() {
  const [stage, setStage] = useState(1);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [resetCode, setResetCode] = useState("");
  const [newPass, setNewPass] = useState("");
  const [newPassConfirm, setNewPassConfirm] = useState("");

  const onChangeEmail = (e) => setEmail(e.target.value);
  const onChangeResetCode = (e) => setResetCode(e.target.value);
  const onChangeNewPass = (e) => setNewPass(e.target.value);
  const onChangeNewPassConfirm = (e) => setNewPassConfirm(e.target.value);
  const navigate = useNavigate();

  const requestCode = (e) => {
    e.preventDefault();
    setLoading(true);
    getQueuesAPI(
      "send-dashboard-password-reset-code",
      { email: email },
      (data) => {
        if (data === "Email Successfully Sent") {
          setStage(2);
        } else {
          setError("Email not found");
          alert(error);
          setTimeout(() => setError(""), 1500);
        }
        setLoading(false);
      }
    );
  };

  const verifyCode = (e) => {
    e.preventDefault();
    setLoading(true);
    getQueuesAPI(
      "validate-dashboard-password-reset-code",
      {
        email: email,
        resetCode: resetCode,
      },
      (data) => {
        if (data === "Reset Code Valid") {
          setStage(3);
        } else {
          setError("Try Again.");
          alert(error);
        }
        setLoading(false);
      }
    );
  };

  const changePassword = (e) => {
    e.preventDefault();
    setLoading(true);
    getQueuesAPI(
      "reset-password-dashboard",
      {
        email: email,
        resetCode: resetCode,
        newPass: newPass,
        newPassVerification: newPassConfirm,
      },
      (data) => {
        if (data === "Password Reset Successfully") {
          alert("Password Reset Successfully!");
          navigate("/auth");
        } else {
          setError("Try Again.");
          alert(error);
        }
        setLoading(false);
      }
    );
  };

  return (
    <div className="centered-container">
      <div className="m-4">
        {loading ? (
          <Spinner size={60} />
        ) : (
          <img alt="" width="225" src={logo} />
        )}
      </div>
      {stage === 1 ? (
        <>
          <p className="">
            Enter your email and we will send you a link to reset your password.
          </p>
          <form onSubmit={requestCode} className="col-4 p-0">
            <input
              required
              className="form-control mb-3"
              autoComplete="off"
              placeholder="E-mail"
              value={email}
              onChange={onChangeEmail}
            />
            <Button className="w-100" type="submit" text="Reset Password" />
          </form>
        </>
      ) : stage === 2 ? (
        <>
          <p className="">Enter the code you were sent.</p>
          <form onSubmit={verifyCode} className="col-4 p-0">
            <input
              readOnly
              required
              className="form-control mb-3"
              autoComplete="off"
              placeholder="E-mail"
              value={email}
              onChange={onChangeEmail}
            />
            <input
              required
              className="form-control mb-3"
              autoComplete="off"
              placeholder="6-Digit Code"
              value={resetCode}
              onChange={onChangeResetCode}
            />
            <Button className="w-100" type="submit" text="Verify Code" />
          </form>
        </>
      ) : (
        <>
          <p className="">Enter new password.</p>
          <form onSubmit={changePassword} className="col-4 p-0">
            <input
              readOnly
              required
              className="form-control mb-3"
              autoComplete="off"
              placeholder="E-mail"
              value={email}
              onChange={onChangeEmail}
            />
            <input
              readOnly
              required
              className="form-control mb-3"
              autoComplete="off"
              placeholder="6-Digit Code"
              value={resetCode}
              onChange={onChangeResetCode}
            />
            <input
              type="password"
              required
              className="form-control mb-3"
              autoComplete="off"
              placeholder="New Password"
              value={newPass}
              onChange={onChangeNewPass}
            />
            <input
              type="password"
              required
              className="form-control mb-3"
              autoComplete="off"
              placeholder="New Password (Again)"
              value={newPassConfirm}
              onChange={onChangeNewPassConfirm}
            />
            <Button className="w-100" type="submit" text="Change Password" />
          </form>
        </>
      )}
    </div>
  );
}
