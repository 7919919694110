import { useEffect, useState } from "react";

export const useLocations = () => {
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    setLocations(require("./floors/locations.json").locations);
  }, []);

  return { locations, setLocations };
};
