import moment from "moment";

export const getQueuesAPI = (url, body, callback, token) => {
  fetch("https://api.queuesapp.com/" + url, {
    headers: { "Content-Type": "application/json", token: token },
    method: "POST",
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((data) => callback(data));
};

export const formatTimestamp = (timestamp) => {
  let year = timestamp.getFullYear();
  let month = ("0" + (timestamp.getMonth() + 1)).slice(-2);
  let day = ("0" + timestamp.getDate()).slice(-2);
  let hours = ("0" + timestamp.getHours()).slice(-2);
  let minutes = ("0" + timestamp.getMinutes()).slice(-2);

  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

export const generateDay = (timestamp) => {
  let day = [];
  for (let x = 0; x < 1440; x++) {
    day.push(
      formatTimestamp(
        moment(timestamp).startOf("day").add(x, "minutes").toDate()
      )
    );
  }
  return day;
};

export const getStreaks = (points) => {
  let streaks = [];
  let currentStreak = [
    { time: points[0].time, occupancy: points[0].occupancy },
  ];

  for (let i = 1; i < points.length; i++) {
    if (
      points[i].occupancy > 0 &&
      currentStreak[currentStreak.length - 1].occupancy > 0
    ) {
      currentStreak.push({
        time: points[i].time,
        occupancy: points[i].occupancy,
      });
    } else {
      streaks.push(currentStreak);
      currentStreak = [
        { time: points[i].time, occupancy: points[i].occupancy },
      ];
    }
  }

  streaks.push(currentStreak);
  return streaks.filter((x) => x.length > 14);
};

export const getChanges = (lastLoggedIn) => {
  let allChanges = require("./floors/changes.json");
  return allChanges;
};
