import { createContext, useState } from "react";

const AuthContext = createContext();

export const AuthContextProvider = (props) => {
  const [email, setEmail] = useState(localStorage.getItem("email"));
  const [firstName, setFirstName] = useState(localStorage.getItem("firstName"));
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [tokenExpirationTime, setTokenExpirationTime] = useState(
    localStorage.getItem("tokenExpirationTime")
  );
  const [authLocations, setAuthLocations] = useState(
    JSON.parse(localStorage.getItem("authLocations"))
  );

  const login = (
    email,
    firstName,
    token,
    tokenExpirationTime,
    authLocations
  ) => {
    setEmail(email);
    setFirstName(firstName);
    setToken(token);
    setTokenExpirationTime(tokenExpirationTime);
    setAuthLocations(authLocations);
    localStorage.setItem("email", email);
    localStorage.setItem("firstName", firstName);
    localStorage.setItem("token", token);
    localStorage.setItem("tokenExpirationTime", tokenExpirationTime);
    localStorage.setItem("authLocations", JSON.stringify(authLocations));
  };

  const logout = () => {
    setEmail(null);
    setFirstName(null);
    setToken(null);
    setTokenExpirationTime(null);
    setAuthLocations([]);
    localStorage.removeItem("email");
    localStorage.removeItem("firstName");
    localStorage.removeItem("token");
    localStorage.removeItem("tokenExpirationTime");
    localStorage.removeItem("authLocations");
  };

  const loggedIn = !!email;

  const contextValue = {
    email: email,
    firstName: firstName,
    token: token,
    tokenExpirationTime: tokenExpirationTime,
    authLocations: authLocations,
    login: login,
    logout: logout,
    loggedIn: loggedIn,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
