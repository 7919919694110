export default function Input({
  type,
  className,
  size,
  placeholder,
  onChange,
  value,
  min,
  max,
  step,
  required,
  readOnly,
}) {
  return (
    <input
      type={type}
      className={
        className + " form-control" + (size === "sm" ? " form-control-sm" : "")
      }
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      min={min}
      max={max}
      step={step}
      required={required}
      readOnly={readOnly}
    />
  );
}
