import Icon from "./Icon";

export default function Button({
  text,
  type = "button",
  icon,
  className = "",
  size,
  onClick,
  target,
  toggle,
  children,
}) {
  return (
    <button
      type={type}
      data-bs-toggle={toggle}
      data-bs-target={target ? "#" + target : null}
      onClick={onClick}
      className={"btn " + className + (size === "sm" ? " btn-sm" : "")}>
      {icon && <Icon name={icon} className={text ? "me-2" : ""} />}
      <span>{text}</span>
      {children}
    </button>
  );
}
